import { createStore } from 'vuex'
import websocket from '../libs/websocket'
import config from '../../config'



export default createStore({
    state: {
        deviceId: '',
        regiserId: '',
        socket: null,
        serverConnected: false,
        isActived: false,
        teleProfile: [],
        user: {},
        display_tele_error: false,
        search_key: '',
        proxies: []
    },
    getters: {
        getDisplayTeleError: state => {
            return state.display_tele_error
        },
        getTeleById: state => (teleId) => {
            return state.teleProfile.find(el => el.teleId == teleId)
        },
        userConnect: state => {
            return state.user
        },
        connectState: state => {
            return state.serverConnected
        },
        getDeviceId: state => {
            return state.deviceId
        },
        getProxies: state => {
            return state.proxies
        },
        getTeleList: state => {
            let result = state.teleProfile.filter(el => {
                if (state.display_tele_error) return el 
                else return el.is_baned == 0
            })

            if (state.search_key) {
                var strRegExPattern = '\\b.*'+String(state.search_key).toLowerCase()+'.*\\b'; 
                return result.filter(el => {
                    const string = `${el.teleId}${el.phone}${el.proxy_host}:${el.proxy_port}:${el.proxy_username}:${el.proxy_password}${el.username}${String(el.first_name).toLowerCase()} ${String(el.last_name).toLowerCase()}`
                    return string.toLowerCase().match(strRegExPattern)
                })
            }
            return result
        },
        serverConnected: state => {
            return state.serverConnected || false
        },
        appActived: state => {
            return state.isActived || false
        },
        acceptRequest: state => {
            return state.acceptRequest || false
        },
    },
    mutations: {

        UPDATE_SEARCH_TEXT (state, keyword) {
            state.search_key = keyword
        },

        CHANGE_DISPLAY_TELE_ERROR (state) {
            state.display_tele_error = !state.display_tele_error
        },

        UPDATE_DEVICE_ID (state, deviceid) {
            state.deviceId = deviceid
        },
        UPDATE_SOCKET (state, socket) {
            state.socket = socket
        },
        UPDATE_REGISTER_ID (state, data) {
            state.regiserId = data?.registerId
            state.isActived = data?.isActive
        },
        UPDATE_SERVER_CONNECTED (state, serverConnected) {
            state.serverConnected = serverConnected
        },


        SET_USER (state, payload) {
            state.user = payload
        },

        TELTE_DELETE (state, teleId) {
            const index = state.teleProfile.findIndex(el => el.teleId == teleId)
            state.teleProfile.splice(index, 1)
        },


        UPDATE_PROXY (state, payload) {
            const { detele, update } = payload

            if (detele) {
                const index = state.proxies.findIndex(el => el.id == detele)
                state.proxies.splice(index, 1)
            }

            if (update) {
                for (const proxy of update) {
                    const indexUpdate = state.proxies.findIndex(el => el.id == proxy.id)
                    if (indexUpdate != -1) {
                        state.proxies[indexUpdate].used = proxy.used
                        state.proxies[indexUpdate].used_all = proxy.used_all
                    } else {
                        state.proxies.push(proxy)
                    }
                }
            }


        },

        TELTE_UPDATE (state, payload) {
            const {
                teleId,
                property,
                value
            } = payload

            const index = state.teleProfile.findIndex(el => el.teleId == teleId)
            if (state.teleProfile[index]) {
                state.teleProfile[index][property] = value
            }
        },

        TELE_UPDATE_MUL (state, payload) {
            const { teleId, data } = payload
            const index = state.teleProfile.findIndex(el => el.teleId == teleId)
            for (const key in data) {
                state.teleProfile[index][key] = data[key]
            }
        },

        ADD_TELE (state, tele) {
            const checkExist = state.teleProfile.find(el => el.teleId == tele.teleId)
            if (!checkExist) {
                state.teleProfile.push({
                    ...tele,
                    loading_open: false
                })
            }
        },

        ADD_PROXY (state, proxy) {
            const checkExist = state.proxies.find(el => el.id == proxy.id)
            if (!checkExist) {
                state.proxies.push({
                    ...proxy
                })
            }
        } 


    },
    actions: {

        logout ({commit}) {
            commit('SET_USER', {})
        },

        fetchProxy ({}, payload) {
            window.websocket.emit('FETCH_PROXIES')
        },

        async deleteTeleError ({}, payload) {
            window.websocket.emit('DELETE_ALL_TELE_ERROR', payload)
        },

        async teleBanChange ({}, payload) {
            window.websocket.emit('TELE_BAN_CHANGE', payload)
        },

        async updateProxyTele ({}, payload) {
            window.websocket.emit('CHANGE_PROXY', payload)
        },

        async deleteTele ({}, teleId) {
            window.websocket.emit('DELETE_TELE', teleId)
        },

        async addXBlast ({}, payload) {
            window.websocket.emit('ADD_XBLAST', payload)
        },

        async addGamee ({}, payload) {
            window.websocket.emit('ADD_GAMEE', payload)
        },

        async addTele ({ state }, payload) {
            window.websocket.emit('ADD_TELE', payload)
        },

        async teleUpdateLocalStorage ({ state }, payload) {
            window.websocket.emit('TELE_UPDATE_LOCAL_STORAGE', payload)
        },

        async teleUpdateInfo ({ state }, payload) {
            const { teleId, teleInfo } = payload
            
            const telegram = state.teleProfile.find(el => el.teleId == teleId)

            if (!telegram) return

            if (
                telegram?.username != teleInfo?.username ||
                telegram?.phone != teleInfo?.phone ||
                telegram?.first_name != teleInfo?.first_name ||
                telegram?.last_name != teleInfo?.last_name ||
                telegram?.access_hash != teleInfo?.access_hash
            ) {
                window.websocket.emit('TELE_UPDATE_INFO', payload)
            }

        },


        async handleBrowser ({state}) {

            if (window?.electronAPI) {
                window.electronAPI.onUpdateOpenProfile((data) => {
                    const { teleId } = data
    
                    const index = state.teleProfile.findIndex(el => el.teleId == teleId)
                    if (index != undefined || index != -1) {
                        state.teleProfile[index].loading_open = false
                    }
                })
            }
            
        },

        async fetchDeviceId ({ commit, state, dispatch }) {
            return new Promise (async (resolve, reject) => {

                let deviceId = 'GC_DC53601AAD83'

                if (window?.electronAPI) {
                    deviceId = await window.electronAPI.updateDeviceId()
                }

                commit('UPDATE_DEVICE_ID', deviceId)

                const socket = websocket.init({
                    wsDomain: config.wsDomain,
                    wsPath: config.wsPath
                })

                window.websocket = socket.socket

                commit('UPDATE_SOCKET', socket.socket)

                dispatch('handleBrowser')

                socket.socket.on("disconnect", () => {
                    commit('UPDATE_SERVER_CONNECTED', false)
                });


                resolve(socket.socket)
            })

        }
    }
})