const { io } = require("socket.io-client");

const WebSocket = {
    init: (options, connectData) => {

        this.socket = io(`${options.wsDomain}/${options.wsPath}`, {
            reconnectionDelayMax: 10000,
        });


        this.socket.on("connect", () => {

            if (connectData) {
                this.socket.emit('device:init', connectData)
            }

            
        });

        this.socket.on("disconnect", () => {
            
        });


        return this
    }


}

module.exports = WebSocket