<script>

export default {
    data() {
        return {

        }
    },
    props: {
        accountId: String,
        gameData: Object
    },
    methods: {
        resolveState () {
            switch (this.gameData?.status) {
                case 0:
                    return { color: 'warning', text: 'Đang chuẩn bị' }
                case 1:
                    return { color: 'success', text: 'Đang hoạt động' }
                case 2:
                    return { color: 'red', text: 'Mất kết nối' }
                case 4:
                    return { color: 'red', text: 'Số dư không đủ' }
                default:
                    return { color: 'secondary', text: 'Chưa rõ' }
            }
            
        },

        changeGameState (accountId, state) {
            this.$store.dispatch('changeGameState', { accountId, gameState: state })
        },

        gameStop (accountId) {
            this.$store.dispatch('resolveGameProcess', accountId)
        }

    }
}

</script>


<template>
    <v-card>
        <v-card-text class="">
            <h6 class="text-subtitle-2 font-weight-black text-primary">{{ gameData.gameName }}</h6>
            <p><strong>User:</strong> {{ gameData?.username }}</p>
            <p><strong>Balance:</strong> {{ gameData?.balance }}</p>
            <p><strong>Counter:</strong> {{ gameData?.counter }} /{{ gameData?.target }}</p>
            <p><strong>Ping:</strong> {{ gameData?.ping }} </p>
            <p><strong>Status:</strong> <v-chip variant="flat" size="small" :color="resolveState().color">{{ resolveState().text }}</v-chip></p>
            <div class="d-flex">
                <v-btn variant="text" color="primary" size="small" @click="changeGameState(gameData.accountId, 0)" v-if="gameData.gameState == 1">Tạm dừng</v-btn>
                <v-btn variant="text" color="primary" size="small" @click="changeGameState(gameData.accountId, 1)" v-if="gameData.gameState == 0">Tiếp tục</v-btn>
                <v-btn variant="text" color="primary" size="small" @click="gameStop(gameData.accountId)">Dừng</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>