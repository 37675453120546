<script>

import GameProcess from '../components/GameProcess.vue'
import DialogCloseBtn from '../components/DialogCloseBtn.vue'
import io from 'socket.io-client'
import axios from '@axios'
import config from '../../config'

export default {
    data () {
        return {
            search: '',
            searchProxy: '',

            checkLive: {
                loadingCheck: false,
                popup: false,
                accountLength: null,
                accountLiveLength: null,
                accountDieLength: null,
                accountError: '',
                accountNosetUsernameLength: null,
            },

            proxy: {
                popup: false,
            },
            addProxy: {
                popup: false,
                proxyValidCount: 0,
                proxyList: ''
            },

            // user: {},
            openBrowserLogin: {
                dialog: false,
                proxy: '',
                proxy_used: 0
            },
            openDialogChangeProxy: {
                dialog: false,
                proxy: '',
                teleId: '',
            },
            teleProfile: [],
            xBlastData: {
                dialog: false,
                ref: '',
                tele: {}
            }
        }
    },
    components: {
        GameProcess,
        DialogCloseBtn
    },
    methods: {
        sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        chiaNhoMang(arr, size) {
            const result = [];
            for (let i = 0; i < arr.length; i += size) {
                result.push(arr.slice(i, i + size));
            }
            return result;
        },

        changeDisplayTeleError () {
            this.$store.commit('CHANGE_DISPLAY_TELE_ERROR')
        },

        checkLivePopup () {
            this.checkLive.popup = true
        },

        async checkTeleLiveHandle () {

            this.checkLive.loadingCheck = false
            this.checkLive.accountLength = null
            this.checkLive.accountLiveLength = null
            this.checkLive.accountDieLength = null
            this.checkLive.accountNosetUsernameLength = null
            this.checkLive.accountError = ''
            
            const teleLive = this.telegram.filter(el => !el.is_baned)
            const username = teleLive.map(el => el.username)
            const notSetUsername = teleLive.filter(el => !el.username)

            this.checkLive.accountLength = teleLive.length
            this.checkLive.accountNosetUsernameLength = notSetUsername.length

            this.checkLive.loadingCheck = true

            const mangChiaNho = this.chiaNhoMang(username, 20)

            for (let index = 0; index < mangChiaNho.length; index++) {

                const arrUsername = mangChiaNho[index];

                try {
                    
                    const response = await axios.post('/profile/checkLive', {
                        username: arrUsername
                    })

                    const responseData = response.data
                
                    if (responseData.error == false) {

                        const teleData = responseData.data

                        const teleLiveLength = teleData.filter(el => el.live && !el.error).length
                        const teleDieLength = teleData.filter(el => !el.live && !el.error).length
                        const teleError = teleData.filter(el => el.error)

                        
                        for (const tele of teleData) {

                            const findTele = teleLive.find(el => el.username == tele.username)

                            if (tele.live && !tele.error) {
                                this.$store.dispatch('teleBanChange', {
                                    teleId: findTele.teleId,
                                    state: 0
                                })
                            }
                            
                            if (!tele.live && !tele.error) {
                                this.$store.dispatch('teleBanChange', {
                                    teleId: findTele.teleId,
                                    state: 1
                                })
                            }
                        }

                        if (!this.checkLive.accountLiveLength) {
                            this.checkLive.accountLiveLength = teleLiveLength
                        } else {
                            this.checkLive.accountLiveLength += teleLiveLength
                        }
                    

                        if (!this.checkLive.accountDieLength) {
                            this.checkLive.accountDieLength = teleDieLength
                        } else {
                            this.checkLive.accountDieLength += teleDieLength
                        }


                        if (!this.checkLive.accountError) {
                            this.checkLive.accountError = teleError.map(el => el.username).join(', ')
                        } else {
                            this.checkLive.accountError += ', ' + teleError.map(el => el.username).join(', ')
                        }

                    } else {
                        await this.sleep(3000)
                        index--
                    }


                } catch (error) {
                    alert(error.message)
                    return
                }

                await this.sleep(1000)
            }

            
            this.checkLive.loadingCheck = false

        },

        async checkTeleDieHandle () {

            this.checkLive.loadingCheck = false
            this.checkLive.accountLength = null
            this.checkLive.accountLiveLength = null
            this.checkLive.accountDieLength = null
            this.checkLive.accountNosetUsernameLength = null
            this.checkLive.accountError = ''

            const teleLive = this.telegram.filter(el => el.is_baned == 1)
            const username = teleLive.map(el => el.username)
            const notSetUsername = teleLive.filter(el => !el.username)

            this.checkLive.accountLength = teleLive.length
            this.checkLive.accountNosetUsernameLength = notSetUsername.length

            this.checkLive.loadingCheck = true

            const mangChiaNho = this.chiaNhoMang(username, 20)

            for (let index = 0; index < mangChiaNho.length; index++) {
                
                const arrUsername = mangChiaNho[index];

                try {
                    
                    const response = await axios.post('/profile/checkLive', {
                        username: arrUsername
                    })

                    const responseData = response.data
                
                    if (responseData.error == false) {

                        const teleData = responseData.data

                        const teleLiveLength = teleData.filter(el => el.live && !el.error).length
                        const teleDieLength = teleData.filter(el => !el.live && !el.error).length
                        const teleError = teleData.filter(el => el.error)

                        
                        for (const tele of teleData) {

                            const findTele = teleLiveLength.find(el => el.username == tele.username)

                            if (tele.live) {
                                this.$store.dispatch('teleBanChange', {
                                    teleId: findTele.teleId,
                                    state: 0
                                })
                            } else {
                                this.$store.dispatch('teleBanChange', {
                                    teleId: findTele.teleId,
                                    state: 1
                                })
                            }
                        }

                        if (!this.checkLive.accountLiveLength) {
                            this.checkLive.accountLiveLength = teleLiveLength
                        } else {
                            this.checkLive.accountLiveLength += teleLiveLength
                        }


                        if (!this.checkLive.accountDieLength) {
                            this.checkLive.accountDieLength = teleDieLength
                        } else {
                            this.checkLive.accountDieLength += teleDieLength
                        }


                        if (!this.checkLive.accountError) {
                            this.checkLive.accountError = teleError.map(el => el.username).join(', ')
                        } else {
                            this.checkLive.accountError += ', ' + teleError.map(el => el.username).join(', ')
                        }
                    } else {
                        await this.sleep(3000)
                        index--
                    }


                } catch (error) {
                    alert(error.message)
                    return
                }

                await this.sleep(1000)
            }

            
            this.checkLive.loadingCheck = false
            
        },

        autoGetProxy () {
            const proxyList = this.proxies
            const firstProxy = proxyList[0]
            const proxyUsed = proxyList.filter(el => el.used_all == firstProxy.used_all)
            const randomProxy = Math.floor(Math.random() * proxyUsed.length) 
            const pickAProxy = proxyUsed[randomProxy]
            return `${pickAProxy.host}:${pickAProxy.port}:${pickAProxy.username}:${pickAProxy.password}`
        },

        renewProxy () {
            this.openBrowserLogin.proxy = this.autoGetProxy()
        },

        hangeDisplayTeleError () {
            this.$store.commit('CHANGE_DISPLAY_TELE_ERROR')
        },

        async logout () {

            try {
                
                const response = await axios.post('/auth/logout', {
                    device_id: this.deviceId
                })
            
                if (response.data == 'ok') {

                    this.$store.dispatch('logout')

                    this.$router.push({
                        path: '/login'
                    })

                    return
                }

            } catch (error) {
                alert(error.message)
                return
            }

            alert('Có lỗi chưa xác định')
        },

        windowMinimize () {
            if (window?.electronAPI) {
                window.electronAPI.windowMinimize()
            }
        },
        windowClose () {

            if (!confirm(`Xác nhân đóng ?`)) return

            if (window?.electronAPI) {
                window.electronAPI.windowClose()
            }
        },
        deleteTeleGram (teleId) {
            const index = this.teleProfile.findIndex(el => el.teleId == teleId)
            if (index != undefined || index != -1) {
                
                if (confirm(`Xóa profile ${this.teleProfile[index].phone} ?`)) {
                    this.socket.emit('DELETE_TELE', teleId)
                }

            }
        },


        async deleteProxy (proxy) {
            if (!confirm(`Xác nhận xóa proxy [${proxy.host}:${proxy.port}:${proxy.username}:${proxy.password}]?.\nTất cả các telegram đang sử dụng proxy này sẽ được tự động phân bổ sang proxy khác`)) {
                return
            }

            window.websocket.emit('DELETE_PROXY', proxy.id)
            
        },

        async handleAddProxy () {
            if (!confirm('Xác nhận hành động')) {
                return
            }


            this.addProxy.popup = false

            const response = await axios.post('/proxy/add', {
                device_id: this.deviceId,
                proxy: this.addProxy.proxyList
            })

            this.addProxy.proxyList = ''

            const responseData = response.data
            if (!responseData.error) {
                alert(`Đã thêm thành công ${responseData.data.added} proxy mới vào danh sách`)
                this.$store.dispatch('fetchProxy')
            }

        },

        deleteTeleError () {
            if (confirm('Xác nhận xóa tất cả các telegram đang đánh dấu lỗi')) {
                this.$store.dispatch('deleteTeleError')
            }
        },

        async openTeleGram (teleId) {
            const index = this.teleProfile.findIndex(el => el.teleId == teleId)
            if (index != undefined || index != -1) {
                this.teleProfile[index].loading_open = true

                try {
                    const response = await axios.post('/profile/open', {
                        teleId
                    })

                    const responseData = response.data

                    if (!responseData.error) {
                        const { local_storage, proxy } = responseData.data
                        window.electronAPI.openProfile({
                            teleId,
                            local_storage,
                            proxy
                        })
                    } else {
                        this.teleProfile[index].loading_open = false
                    }
                    
                    
                } catch (error) {
                    this.teleProfile[index].loading_open = false
                }

                // this.socket.emit('OPEN_PROFILE', teleId)
            }
        },

        openBrowser () {
            this.openBrowserLogin.proxy = this.autoGetProxy()
            this.openBrowserLogin.dialog = true
        },
        openBrowserHandle () {
            if (window?.electronAPI) {
                this.openBrowserLogin.loading = true
                window.electronAPI.openBrowser(this.openBrowserLogin.proxy)
            }
        },
        
        changeProxy (teleId) {
            const tele = this.teleProfile.find(el => el.teleId == teleId)
            this.openDialogChangeProxy.teleId = teleId
            this.openDialogChangeProxy.proxy = tele.proxy
            this.openDialogChangeProxy.dialog = true
        },

        changeProxyHandle () {
            this.openDialogChangeProxy.dialog = false

            this.socket.emit('CHANGE_PROXY', {
                teleId: this.openDialogChangeProxy.teleId,
                proxy: this.openDialogChangeProxy.proxy
            })
        },
        resolveConnectState () {
            return this.connectState ? { color: 'success', text: 'Online' } : { color: 'warning', text: 'Offline' }
        },
        resolveActiveState () {
            return this.user?.id ? { color: 'success', text: this.user.email } : { color: 'warning', text: 'Chưa kích hoạt' }
        },
        resolveAcceptState () {
            return this.acceptRequest ? { color: 'success', text: 'Tiếp nhận yêu cầu' } : { color: 'warning', text: 'Dừng tiếp nhận' }
        },
        requestDeActiveRequest () {
            window.websocket.emit('device:update_accept_request', !this.acceptRequest)
        }
    },
    computed: {
        displayTeleError () {
            return this.$store.getters['getDisplayTeleError']
        },
        deviceId () {
            return this.$store.getters['getDeviceId']
        },
        serverConnected () {
            return this.$store.getters['serverConnected']
        },
        appActive () {
            return this.$store.getters['appActived']
        },
        acceptRequest () {
            return this.$store.getters['acceptRequest']
        },
        connectState () {
            return this.$store.getters['connectState']
        },
        user () {
            return this.$store.getters['userConnect']
        },
        proxies () {
            let proxies = this.$store.getters['getProxies']

            var strRegExPattern = '\\b.*'+String(this.searchProxy).toLowerCase()+'.*\\b'; 
            proxies = proxies.filter(el => {
                const string = `${String(el.host).toLowerCase()}:${String(el.port).toLowerCase()}:${String(el.username).toLowerCase()}:${String(el.password).toLowerCase()}`
                return string.toLowerCase().match(strRegExPattern)
            })

            return proxies.sort((a,b) => a.used_all - b.used_all)
        },
        telegram () {
            return this.$store.getters['getTeleList']
        },
    },
    created () {
        
    },
    mounted () {

        if (window?.electronAPI) {

            window.electronAPI.onUpdateTeleLocalStorage((data) => {

                this.$store.dispatch('teleUpdateLocalStorage', data)

            })

            window.electronAPI.onTeleUpdateInfo((data) => {

                this.$store.dispatch('teleUpdateInfo', data)

            })

            window.electronAPI.onUpdateOpenBrowser((data) => {

                this.openBrowserLogin.loading = false

                if (!data.state) { return }

                this.openBrowserLogin.dialog = false

                const {
                    teleInfo,
                    localStorageData,
                    proxy
                } = data


                this.$store.dispatch('addTele', {
                    deviceId: this.deviceId,
                    teleInfo,
                    localStorageData,
                    proxy
                })

            })

            window.electronAPI.GameeUpdateProfile((data) => {
                const {
                    link,
                    teleId
                } = data

                this.$store.dispatch('addGamee', {
                    link,
                    teleId
                })

            })

        }
    },
    watch: {
        'openBrowserLogin.proxy': function (value) {
            const proxy = String(value).split(':')
            if (proxy.length != 4) {
                this.openBrowserLogin.proxy_used = `Proxy không hợp lệ`
                return
            }

            const proxyFind = this.proxies.find(el => el.host == proxy[0] && el.port == proxy[1] && el.username == proxy[2]  && el.password == proxy[3])

            if (!proxyFind) {
                this.openBrowserLogin.proxy_used = `Không tìm thấy trong kho`
                return
            }

            this.openBrowserLogin.proxy_used = `Đã sử dụng ${proxyFind.used_all}`

        },
        'addProxy.proxyList': function (value) {
            const proxyList = {}
            const listProxy = String(value).split('\n')
            for (let index = 0; index < listProxy.length; index++) {
                const proxyStr = listProxy[index];
                if (!proxyStr) continue
                const proxyParse = String(proxyStr).split(':')
                if (proxyParse.length == 4 && !proxyList[proxyStr]) {
                    proxyList[proxyStr] = ''
                }
            }

            
            this.addProxy.proxyValidCount = Object.keys(proxyList).length

        },
        search: function (value) {
            this.$store.commit('UPDATE_SEARCH_TEXT', value)
        },
    }
}

</script>


<template>
    <div style="height: calc(100vh); display: flex;flex-direction: column;  overflow-y: hidden; ">


        <VDialog scrollable v-model="addProxy.popup" width="600px">
            <DialogCloseBtn @click="addProxy.popup = false" />
            <VCard title="Thêm proxy">
                <VCardText>
                    <code>host:port:username:password</code>
                </VCardText>
                <VCardText>
                    <VTextarea rows="10" v-model="addProxy.proxyList"></VTextarea>
                </VCardText>
                <VCardText class="justify-space-between d-flex align-center">
                    <span><span class="font-weight-black">{{ addProxy.proxyValidCount }}</span> proxy hợp lệ</span>
                    <VBtn @click="handleAddProxy">Thêm</VBtn>
                </VCardText>
            </VCard>
            
        </VDialog>

        <VDialog scrollable fullscreen v-model="proxy.popup">
            <DialogCloseBtn @click="proxy.popup = false" />
            <VCard title="Proxy">
                
                <div class="mx-6">
                    <div class="d-flex justify-space-between align-center">
                        <span>Số lượng: {{ proxies.length }}</span>
                        <VBtn @click="addProxy.popup = true">Thêm proxy</VBtn>
                    </div>
                    <div class="mb-3 d-flex justify-space-between align-center">
                        <div class="d-flex align-center">
                            <input class="me-6 text-black input-search" placeholder="Tìm kiếm" v-model="searchProxy"/>
                        </div>
                    </div>
                </div>
                
                
                <div class="mx-6">
                    <div class="table">
                        <div class="table-row table-header">
                            <div class="table-cell">STT</div>
                            <div class="table-cell">HOST</div>
                            <div class="table-cell">PORT</div>
                            <div class="table-cell">USERNAME</div>
                            <div class="table-cell">PASSWORD</div>
                            <div class="table-cell">ĐÃ DÙNG</div>
                            <div class="table-cell">TỔNG SỬ DỤNG</div>
                            <div class="table-cell">HÀNH ĐỘNG</div>
                        </div>
                        <div class="table-row" v-for="(proxy, index) in proxies" :key="proxy.id">
                            <div class="table-cell">{{ index + 1 }}</div>
                            <div class="table-cell">{{ proxy.host }}</div>
                            <div class="table-cell">{{ proxy.port }}</div>
                            <div class="table-cell">{{ proxy.username }}</div>
                            <div class="table-cell">{{ proxy.password }}</div>
                            <div class="table-cell">{{ proxy.used }}</div>
                            <div class="table-cell">{{ proxy.used_all }}</div>
                            <div class="table-cell">
                                <VBtn @click="deleteProxy(proxy)" size="26" color="red">
                                    <VIcon icon="tabler-trash"/>
                                </VBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </VCard>
        </VDialog>


        <VDialog scrollable v-model="checkLive.popup" width="600">
			<DialogCloseBtn @click="checkLive.popup = false" />
			<VCard title="Check Live Telegram">

                <div class="mx-6 mt-4">
                    <div class="d-flex align-center">
                        <VBtn size="small" :loading="checkLive.loadingCheck" class="me-2" @click="checkTeleLiveHandle()">Check tele sống</VBtn>
                        <VBtn size="small" :loading="checkLive.loadingCheck" color="warning" @click="checkTeleDieHandle()">Check tele đánh dấu lỗi</VBtn>
                    </div>
                </div>

                <div class="mx-6 my-4">
                    <div class="table">
                        <div class="table-row">
                            <div class="table-cell">Tổng số account</div>
                            <div class="table-cell">{{ checkLive.accountLength == null ? '--' : checkLive.accountLength }}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell">Số telegram sống</div>
                            <div class="table-cell">{{ checkLive.accountLiveLength == null ? '--' : checkLive.accountLiveLength }}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell">Số telegram chết</div>
                            <div class="table-cell">{{ checkLive.accountDieLength == null ? '--' : checkLive.accountDieLength }}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell">Check lỗi</div>
                            <div class="table-cell">{{ checkLive.accountError }}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-cell">Telegram chưa đặt username</div>
                            <div class="table-cell">{{ checkLive.accountNosetUsernameLength == null ? '--' : checkLive.accountNosetUsernameLength }}</div>
                        </div>
                    </div>
                </div>

			</VCard>
		</VDialog>


        <VDialog scrollable v-model="openBrowserLogin.dialog" width="600">
			<DialogCloseBtn @click="openBrowserLogin.dialog = false" />
			<VCard title="Proxy">
				<VCardText>
					<div class="d-flex align-center mb-3">

                        <VTextField v-model="openBrowserLogin.proxy" class="me-3" placeholder="Proxy "></VTextField>
                        <VBtn @click="renewProxy">GET Proxy</VBtn>
                    </div>
                    <span>{{ openBrowserLogin.proxy_used }}</span>
					<div class="d-flex justify-end">
						<VBtn @click="openBrowserHandle" :loading="openBrowserLogin.loading">Open</VBtn>
					</div>
				</VCardText>
			</VCard>
		</VDialog>

        <div class="d-flex align-center justify-space-between title-bar">
            <div class="d-flex align-center">
                <h1 class="ms-5 text-h6">Telegram</h1>
                <div class="left-sidebar ms-4">
                    <v-btn class="text-none me-3" @click="openBrowser" color="primary"  v-bind="props" size="small" density="comfortable" variant="tonal">
                        Đăng nhập Telegram
                    </v-btn>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn class="text-none" color="primary" variant="tonal" v-bind="props" size="small" density="comfortable">
                                Chức năng
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item value="1">
                                <v-list-item-title @click="checkLivePopup">Check Live</v-list-item-title>
                            </v-list-item>
                            <v-list-item value="2">
                                <v-list-item-title @click="proxy.popup = true">Proxy</v-list-item-title>
                            </v-list-item>
                            <v-list-item value="3">
                                <v-list-item-title @click="changeDisplayTeleError" class="">
                                    <div class="d-flex align-center">
                                        <span class="text-white ma-0 pa-0 me-2">Hiển thị telegram lỗi</span>
                                        <input type="checkbox" v-model="displayTeleError" class="ma-0 pa-0"/>
                                    </div>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <div class="center-sidebar d-flex align-center">
                <div v-if="user.email" class="d-flex align-center">
                    <input class="me-6 text-white input-search" placeholder="Tìm kiếm" v-model="search"/>
                </div>
            </div>
            <div class="right-sidebar d-flex align-center">
                
                <div style="border-right: 1px solid #666;" class="me-2 pe-2 text-small">
                    <v-chip size="small"  variant="tonal" density="comfortable" :color="resolveConnectState().color">{{ resolveConnectState().text }}</v-chip>
                </div>
                <div class="auth me-3">
                    <span v-if="!user.email">Chưa đăng nhập</span>
                    <v-menu v-else>
                        <template v-slot:activator="{ props }">
                            <v-btn class="text-none" color="primary"  v-bind="props" size="small" variant="text" density="comfortable">
                                {{ user.email }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item value="1">
                                <v-list-item-title @click="changePassword" class="">Đổi mật khẩu</v-list-item-title>
                            </v-list-item>
                            <v-list-item value="2">
                                <v-list-item-title @click="logout">Đăng xuất</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <div>
                    <button @click="windowMinimize" class="me-0 button-sidebar">
                        <VIcon icon="tabler-minus"/>
                    </button>
                    <button @click="windowClose" class="me-0 button-sidebar">
                        <VIcon icon="tabler-x"/>
                    </button>
                </div>
            </div>
        </div>
        <div style="">
            <router-view></router-view>
        </div>
        <!-- <div style="position: sticky; bottom: 0px; z-index: 999; box-shadow: rgb(83, 79, 126) 0px 10px 50px 0px">
            <v-card style="border-radius: 0px;">
                <v-card-text class="py-2">
                    <div class="d-flex justify-space-between align-center">
                        <p class="text-subtitle-2 mb-0">Device ID: <code>{{ deviceId }}</code></p>
                        <div>
                            <VBtn size="small" class="me-2" to="/telegram">Telegram</VBtn>
                            <VBtn size="small" to="/twitter" color="blue">Twitter</VBtn>
                        </div>
                        <div class="d-flex">
                            <p class="text-subtitle-2 mr-6 mb-0">
                                Network: 
                                <v-chip variant="flat" size="small" :color="resolveConnectState().color">{{ resolveConnectState().text }}</v-chip>
                            </p>
                            <p class="text-subtitle-2 mr-6 mb-0">
                                Registed: 
                                <v-chip variant="flat" size="small" :color="resolveActiveState().color">{{ resolveActiveState().text }}</v-chip>
                            </p>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div> -->
        
    </div>
</template>



<style lang="scss">

.left-sidebar , .center-sidebar, .right-sidebar {
    -webkit-app-region: no-drag;
}

.right-sidebar {

    .button-sidebar {
        width: 30px;
        height: 30px;
        outline: 1px #4d4d4d;
    
        &:hover {
            cursor: pointer;
            background-color: #555;
        }
    }

    
    .auth {
        font-size: 11px;
        color: #29b8b8;
    }
}

#teletable {
    table {
        tr {
            td {
                height: 40px;
                border-bottom: 1px solid #c5c5c5;
                border-right: 1px solid #e0e0e0;
            }
            th {
                height: 30px !important;
                background-color: #1c3a66 !important;
                color: #fff;
            }
        }
    }
}

.btn-link {
    height: 20px !important;
    
    span {
        font-size: 11px;
    }

}

.text-small {
    font-size: 10px;
}


.input-search {
    width: 300px;
    height: 26px;
    color: #fff !important;
    border: 1px solid #4d4d4d !important;
    outline: none !important;
    border-radius: 5px;
    padding: 3px 10px;

    &:active {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

.title-bar {
    -webkit-app-region: drag; /* Cho phép kéo di chuyển */
    height: 30px; /* Đặt chiều cao */
    background-color: #333; /* Màu nền */
    color: white; /* Màu chữ */
    text-align: center; /* Căn giữa chữ */
    line-height: 30px; /* Căn chỉnh dòng */

}


.v-menu {

    .v-list {
        background-color: #252525 !important;
        color: #fff;
        padding: 0px;

        .v-list-item {
            border-radius: 0px !important;
            margin: 0px !important;
        }

        .v-list-item-title {
            font-size: 13px;
        }
    }


}


.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}
.table-row {
    display: table-row;
}
.table-cell {
    display: table-cell;
    border: 1px solid #000;
    padding: 2px 6px;
    text-align: left;
}
.table-header {
    background-color: #f2f2f2;
    font-weight: bold;
}

</style>