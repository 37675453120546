<template>
	<LayoutDefault/>
</template>

<script>

import LayoutDefault from './layouts/default.vue'

export default {
	name: 'App',
	components: {
		LayoutDefault
	},
	methods: {
		
	},

    async created () {

		this.$store.dispatch('fetchDeviceId')
		.then(ws => {

			ws.on("connect", () => {
				this.$store.commit('UPDATE_SERVER_CONNECTED', true)
				ws.emit('AUTH', this.deviceId)
			});

			ws.on('DELETE_TELE', (teleId) => {
				this.$store.commit('TELTE_DELETE', teleId)
			})

			ws.on('UPDATE_PROXY', (data) => {
				this.$store.commit('UPDATE_PROXY', data)
			})


			ws.on('OPEN_PROFILE', (data) => {
				const { state, teleId, local_storage, proxy } = data

				if (!state) {
					this.$store.commit('TELTE_UPDATE', {
						teleId,
						property: 'loading_open',
						value: false
					})
				} else {
					window.electronAPI.openProfile({
						teleId,
						local_storage,
						proxy
					})
				}
			})
	

			ws.on('AUTH_DONE', (user) => {

				this.$store.commit('SET_USER', user)
				ws.emit('FETCH_TELE')
				ws.emit('FETCH_PROXIES')
				ws.emit('FETCH_TW')

				this.$router.push('/telegram')

			})


			ws.on('TELE_INFO', (teleProfiles) => {
				for (const tele of teleProfiles) {
					this.$store.commit('ADD_TELE', tele)
				}
			})


			ws.on('PROXY_LIST', (proxies) => {
				for (const proxy of proxies) {
					this.$store.commit('ADD_PROXY', proxy)
				}
			})


			ws.on('CHANGE_INFO', (payload) => {
				this.$store.commit('TELE_UPDATE_MUL', payload)
			})
			
		})
    },
	computed: {
        deviceId () {
            return this.$store.getters['getDeviceId']
        },
	}
}
</script>


<style>

.v-btn__loader >div>svg {
    color: #fff;
}

html {
    overflow-y: hidden !important;
}


.v-table th {
    height: 40px !important;
    background-color: #f9f9f9 !important;
}


* {
    font-size: 0.8rem;
}

.v-table {
	color: #000 !important
}

</style>