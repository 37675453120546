// import { canNavigate } from '@layouts/plugins/casl'
// import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
// import { isUserLoggedIn } from './utils'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: to => {
				return { path: '/login' }
			},
		},
        {
            path: '/dashboard',
            component: () => import('@/pages/dashboard.vue')
        },
        {
            path: '/login',
            component: () => import('@/pages/login.vue')
        },
        {
            path: '/telegram',
            component: () => import('@/pages/telegram/index.vue')
        },
        {
            path: '/telegram/gamee',
            component: () => import('@/pages/telegram/gamee.vue')
        },
        {
            path: '/xblast',
            component: () => import('@/pages/xblast.vue')
        },
        {
            path: '/twitter',
            component: () => import('@/pages/twitter.vue')
        }
	],
})



router.beforeEach(to => {
	// const isLoggedIn = isUserLoggedIn()

	/*
    
	  ℹ️ Commented code is legacy code
    
	  if (!canNavigate(to)) {
		// Redirect to login if not logged in
		// ℹ️ Only add `to` query param if `to` route is not index route
		if (!isLoggedIn)
		  return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })
    
		// If logged in => not authorized
		return next({ name: 'not-authorized' })
	  }
    
	  // Redirect if logged in
	  if (to.meta.redirectIfLoggedIn && isLoggedIn)
		next('/')
    
	  return next()
    
	  */
	
	// if (canNavigate(to)) {
	// 	if (to.meta.redirectIfLoggedIn && isLoggedIn)
	// 		return '/'
	// }
	// else {
	// 	if (isLoggedIn)
	// 		return { name: 'not-authorized' }
	// 	else
	// 		return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
	// }
})
export default router
