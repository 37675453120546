import axios from 'axios'
import config from '../../config'

const axiosIns = axios.create({
// You can add your headers here
// ================================
    baseURL: config.baseUrl,
// timeout: 1000,
// headers: {'X-Custom-Header': 'foobar'}
})

export default axiosIns
