module.exports = {
    baseUrl: 'https://tele.kola.vn/api',
    wsDomain: 'wss://tele.kola.vn',
    frontendUrl: 'https://tele.kola.vn',
    // baseUrl: 'http://localhost:3333/api',
    // wsDomain: 'ws://127.0.0.1:3334',
    wsPath: '',
    appName: 'GAME_CLIENT_V1',
    appType: 'GAME_CLIENT'
}
