import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'

import './styles/styles.scss'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import store from './store'
import router from './router'
import './@core/scss/template/index.scss'
loadFonts()

import mitt from 'mitt';
const emitter = mitt();

// const vuetify = createVuetify({
//     components,
//     directives,
// })


const app = createApp(App)
.use(vuetify)
.use(store)
.use(router);

window.emitter = emitter
app.config.globalProperties.emitter = emitter;

app.mount('#app')
